// src/components/HistoricalSilenceRecord.js
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../components/AuthContext';
import NavButton from '../components/NavButton';
import '../App.css';
import { Typography, Button, Modal, ReactSelect } from '@anchor/react-components';
import Box from "@anchor/react-components/dist/lib/components/Box";
import Table from "@anchor/react-components/dist/lib/components/TableV2";
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const HistoricalSilenceRecord = () => {
    const { getToken } = useContext(AuthContext);
    const [silenceRecords, setSilenceRecords] = useState([]);
    const [selectedOption, setSelectedOption] = useState('historical');
    const [dateRangeForCal, setDateRangeForCal] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({ item: {} });
    const [selectedDuration, setSelectedDuration] = useState(30);
    const [selectedOption1, setSelectedOption1] = useState(null);
    const [timee, setTime] = useState('');
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [recordToDelete, setRecordToDelete] = useState(null);
    const [message, setMessage] = useState(null); // New state for messages
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [isDatePickerOpen, setDatePickerOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10); // Default page size to 10
    const [searchInputs, setSearchInputs] = useState({
        application_name: '',
        cmdb_change_no: '',
        suppression_id: ''
    });
    const [filteredData, setFilteredData] = useState([]);

    const handleTime = (event) => {
        setTime(event.value);
        setSelectedValue(event.value);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handlePageSizeChange = (newSize) => {
        setPageSize(newSize);
        setCurrentPage(1); // Reset to the first page when page size changes
    };

    const handleSubmit = () => {
        console.log(`Submitted action: ${modalContent.action} on row ${modalContent.rowIndex} with duration ${selectedDuration} minutes`);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOpenModal = () => {
        setShowModal(false);
    };

    const formatDate = (inputDate) => {
        const date = new Date(inputDate);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    useEffect(() => {
        const token = getToken();
        if (token) {
            fetchData(selectedOption, token);
        }
    }, [selectedOption]);

    useEffect(() => {
        handleFilterData(); // Filter data whenever search inputs or date range change
    }, [searchInputs, dateRange, silenceRecords]);

    const fetchData = async (option, token) => {
        try {
            const historical_api_endpoint = `${process.env.REACT_APP_STARGATE_API_URL}historical-requests-list`;
            let endpoint = '';
            switch (option) {
                case 'historical':
                    endpoint = historical_api_endpoint;
                    setDateRangeForCal(true);
                    break;
                default:
                    break;
            }

            const response = await fetch(endpoint, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Consumer-Key': process.env.REACT_APP_CONSUMER_KEY, // Example of additional header
                },
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Error fetching data: ${response.status} - ${errorText}`);
            }

            const data = await response.json();
            setSilenceRecords(data);
            setFilteredData(data); // Initialize filteredData with fetched data
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleFilterData = () => {
        let filtered = silenceRecords;

        if (searchInputs.application_name) {
            filtered = filtered.filter((item) =>
                item.application_name &&
                item.application_name.toString().toLowerCase().includes(searchInputs.application_name.toLowerCase())
            );
        }
        if (searchInputs.cmdb_change_no) {
            filtered = filtered.filter((item) =>
                item.cmdb_change_no &&
                item.cmdb_change_no.toString().toLowerCase().includes(searchInputs.cmdb_change_no.toLowerCase())
            );
        }
        if (searchInputs.suppression_id) {
            filtered = filtered.filter((item) =>
                item.suppression_id &&
                item.suppression_id.toString().toLowerCase().includes(searchInputs.suppression_id.toLowerCase())
            );
        }

        if (startDate && endDate) {
            filtered = filtered.filter((item) => {
                const itemDate = new Date(item.start_time);
                return itemDate >= startDate && itemDate <= endDate;
            });
        }

        setFilteredData(filtered);
    };

    const handleInputChange = (event, key) => {
        const value = event.target.value;
        setSearchInputs({
            ...searchInputs,
            [key]: value
        });
    };

    const handleOptionChange = (option) => {
        setDateRangeForCal(true);
        setSelectedOption(option);
    };

    return (
        <>
            <div className="flex justify-center items-center h-full">
                <div className="p-4">
                    <div className="mt-8 flex flex-col items-center">
                        <Typography variant="h4" style={{ fontWeight: 'bold', fontSize: '1.5rem', marginBottom: '1rem' }}> Silencing Records</Typography>
                    </div>
                    <div className="flex mb-4 space-x-4">
                        <NavButton path="/historical-silence-record" label="Historical Silence Records" selected={true} />
                        <NavButton path="/active-silence-record" label="Active Silence Records" selected={false} />
                        <NavButton path="/silence-request-schedule" label="Requests for Silencing Schedule" selected={false} />
                    </div>
                </div>
            </div>

            <div className="flex flex-col items-center mb-4">
                <div className="flex mb-4 space-x-4">
                    <input
                        type="text"
                        value={searchInputs.application_name}
                        onChange={(e) => handleInputChange(e, 'application_name')}
                        placeholder="Search Application"
                        style={{
                            width: '200px',
                            padding: '10px',
                            fontSize: '1em',
                            border: '2px solid black',
                            borderRadius: '5px',
                            textAlign: 'left' // Left align
                        }}
                    />
                    <input
                        type="text"
                        value={searchInputs.cmdb_change_no}
                        onChange={(e) => handleInputChange(e, 'cmdb_change_no')}
                        placeholder="Search CMDB Change No"
                        style={{
                            width: '200px',
                            padding: '10px',
                            fontSize: '1em',
                            border: '2px solid black',
                            borderRadius: '5px',
                            textAlign: 'left' // Left align
                        }}
                    />
                    <input
                        type="text"
                        value={searchInputs.suppression_id}
                        onChange={(e) => handleInputChange(e, 'suppression_id')}
                        placeholder="Search Suppression Id"
                        style={{
                            width: '200px',
                            padding: '10px',
                            fontSize: '1em',
                            border: '2px solid black',
                            borderRadius: '5px',
                            textAlign: 'left' // Left align
                        }}
                    />
                    <div className="relative">
                        <input
                            type="text"
                            value={startDate && endDate ? `${format(startDate, 'dd/MM/yyyy')} - ${format(endDate, 'dd/MM/yyyy')}` : ''}
                            readOnly
                            onClick={() => setDatePickerOpen(!isDatePickerOpen)}
                            className="border border-gray-300 rounded p-2 text-center text-lg cursor-pointer"
                            style={{ width: '300px', textAlign: 'left' }} // Left align
                            placeholder="Select Date Range"
                        />
                        {isDatePickerOpen && (
                            <div className="absolute z-10 mt-2">
                                <DatePicker
                                    selected={startDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                        if (update[1]) setDatePickerOpen(false); // Close when end date is selected
                                    }}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    inline
                                    className="mt-2" // Margin to create space above
                                />
                            </div>
                        )}
                    </div>
                    <button
                        onClick={handleFilterData}
                        style={{
                            padding: '10px 20px',
                            fontSize: '1em',
                            border: 'none',
                            borderRadius: '5px',
                            backgroundColor: '#007BFF',
                            color: 'white',
                            cursor: 'pointer',
                        }}
                    >
                        Search
                    </button>
                </div>
            </div>

            {message && (
                <div className="flex justify-end px-4 py-2 bg-green-500 text-white">
                    {message}
                </div>
            )}

            <div className="flex justify-center">
                <div className="w-full max-w-7xl">
                    <Table
                        defaultData={filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize)}
                        key="Code"
                        zebraStripes
                        overflowX="auto"
                        onPageChange={handlePageChange}
                        onPageSizeOptionsChange={handlePageSizeChange}
                        pageSizeOptions={[10, 20, 30]}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        totalRecords={filteredData.length}
                        showPagination
                        style={{ width: '100%' }}
                        columnData={[
                            {
                                accessorKey: "application_name",
                                header: "Application",
                                id: "application_name",
                                alignData: "left", // Left align
                                enableSorting: true,
                                cell: ({ row }) => <Box textAlign="left">{row.original.application_name || ""}</Box>,
                            },
                            {
                                accessorKey: "start_time",
                                header: "Start Time",
                                id: "start_time",
                                alignData: "left", // Left align
                                enableSorting: true,
                                cell: ({ row }) => <Box textAlign="left">{formatDate(row.original.start_time) || ""}</Box>,
                            },
                            {
                                accessorKey: "end_time",
                                header: "End Time",
                                id: "end_time",
                                alignData: "left", // Left align
                                enableSorting: true,
                                cell: ({ row }) => <Box textAlign="left">{formatDate(row.original.end_time) || ""}</Box>,
                            },
                            {
                                accessorKey: "cmdb_change_no",
                                header: "CMDB Change No",
                                id: "cmdb_change_no",
                                alignData: "left", // Left align
                                enableSorting: true,
                                cell: ({ row }) => <Box textAlign="left">{row.original.cmdb_change_no || ""}</Box>,
                            },
                            {
                                accessorKey: "cmdb_outage_no",
                                header: "Outage No",
                                id: "cmdb_outage_no",
                                alignData: "left", // Left align
                                enableSorting: true,
                                cell: ({ row }) => <Box textAlign="left">{row.original.cmdb_outage_no || ""}</Box>,
                            },
                            {
                                accessorKey: "suppression_id",
                                header: "Suppression Id",
                                id: "suppression_id",
                                alignData: "left", // Left align
                                enableSorting: true,
                                cell: ({ row }) => <Box textAlign="left">{row.original.suppression_id || ""}</Box>,
                            },
                            {
                                accessorKey: "application_name",
                                header: "Github Run",
                                id: "github_workflow",
                                alignData: "left", // Left align
                                enableSorting: true,
                                cell: ({ row }) => (
                                    <Box textAlign="left">
                                        {row.original.github_workflow ? (
                                            <a
                                                href={row.original.github_workflow}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                title={row.original.github_workflow}
                                                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                            >
                                                Link
                                            </a>
                                        ) : (
                                            <span>NA</span>
                                        )}
                                    </Box>
                                )
                            },
                        ]}
                    />
                </div>
            </div>
        </>
    );
};

export default HistoricalSilenceRecord;
